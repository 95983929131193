import type { DialogProviderProps } from "@ariakit/react";
import { X } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogHeading,
  DialogProvider,
  TextField,
} from "@replicate/ui";
import {
  useMutation,
  useQuery,
  type UseMutationOptions,
} from "@tanstack/react-query";
import Cookies from "js-cookie";
import type React from "react";
import { useRef } from "react";
import { toast } from "react-hot-toast";
import { throwForStatus } from "../fetch_helpers";
import { route } from "../urls";
import CopyIconButton from "./copy-icon-button";

interface InviteToOrganizationDialogProps
  extends Omit<DialogProviderProps, "disclosure"> {
  disclosure: React.ReactElement;
  organizationName: string;
}

async function fetchInviteLink(organizationName: string) {
  const response = await fetch(
    route("organization_create_invite", {
      organization_name: organizationName,
    }),
    {
      method: "POST",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
    }
  );
  await throwForStatus(response);
  const { url } = await response.json<{ url: string }>();
  return url;
}

async function inviteEmailToOrganization({
  email,
  organizationName,
}: {
  email: string;
  organizationName: string;
}) {
  const response = await fetch(
    route("api_organization_email_invite", {
      organization_name: organizationName,
    }),
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": Cookies.get("csrftoken") ?? "",
      },
      body: JSON.stringify({ email }),
    }
  );
  await throwForStatus(response);
}

function useCreateInviteLink(organizationName: string) {
  return useQuery({
    queryFn: () => fetchInviteLink(organizationName),
    queryKey: ["invite", organizationName],
    refetchOnWindowFocus: false,
    retry: false,
  });
}

function useInviteEmailToOrganization(
  options: UseMutationOptions<
    void,
    Error,
    { email: string; organizationName: string }
  >
) {
  return useMutation({
    ...options,
    mutationFn: inviteEmailToOrganization,
  });
}

export function InviteToOrganizationDialog({
  disclosure,
  organizationName,
  ...providerProps
}: InviteToOrganizationDialogProps) {
  const { data, isPending, isSuccess } = useCreateInviteLink(organizationName);

  return (
    <DialogProvider {...providerProps}>
      <DialogDisclosure render={disclosure} />
      <Dialog>
        <div className="p-4 relative">
          <div className="absolute top-4 right-4">
            <DialogDismiss>
              <X size={18} weight="bold" />
            </DialogDismiss>
          </div>
          <DialogHeading className="leading-none">
            Invite a new member to{" "}
            <strong className="font-semibold">{organizationName}</strong>
          </DialogHeading>
          <div className="mt-4">
            <InviteForm organizationName={organizationName} />
          </div>
          <hr className="my-4" />
          <div className="space-y-2">
            <p className="text-r8-sm text-r8-gray-11">
              Alternatively, share this link. The link will expire after 24
              hours.
            </p>
            <div className="relative">
              <TextField
                id="invite-link"
                value={isPending ? "Creating..." : data}
                endAdornment={
                  isSuccess ? (
                    <div className="pointer-events-auto">
                      <CopyIconButton content={data} label="Copy link" />
                    </div>
                  ) : undefined
                }
                readOnly
              />
            </div>
          </div>
        </div>
      </Dialog>
    </DialogProvider>
  );
}

function InviteForm({
  organizationName,
}: Pick<InviteToOrganizationDialogProps, "organizationName">) {
  const formRef = useRef<HTMLFormElement>(null);
  const { mutate, isPending } = useInviteEmailToOrganization({
    onSuccess: () => {
      toast.success("Invitation sent.");
      formRef.current?.reset();
    },
    onError: () => {
      toast.error("Failed to send invitation.");
    },
  });

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email") as string;
    if (!email) {
      toast.error("Email address is required.");
      return;
    }

    mutate({
      email,
      organizationName,
    });
  }

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <div className="space-y-2">
        <TextField
          id="email"
          disabled={isPending}
          label="Email address"
          type="email"
          name="email"
          required
          helpText="Enter an email address to invite."
        />
        <Button loading={isPending} className="w-full" type="submit">
          Invite to {organizationName}
        </Button>
      </div>
    </form>
  );
}
