import { CaretRight, Check, HandWaving, X } from "@phosphor-icons/react";
import {
  Button,
  Dialog,
  DialogDisclosure,
  DialogDismiss,
  DialogProvider,
  Label,
  NativeSelect,
  Tab,
  TabList,
  TabPanel,
  TabProvider,
} from "@replicate/ui";
import { partition } from "lodash-es";
import { useState } from "react";
import { useMediaMatch } from "../hooks";
import type {
  AccessToken,
  CogInputPropertyValue,
  Model,
  Version,
} from "../types";
import { route } from "../urls";
import BgGoo from "./api-playground/bg-goo";
import { RunWith, RunWithContext } from "./api-playground/run-with";
import { LogoShorthand } from "./icons/generic/logo-shorthand";

type Models = {
  example_inputs: Record<string, CogInputPropertyValue> | null;
  is_official_model: boolean;
  model: Model;
  type: "suggested" | "recent";
  version: Version;
}[];

export default function Onboarding({
  hasInvitedOrgMembers,
  hasMadeAPIPredictions,
  hasSetupBilling,
  hasConnectedSlack,
  kind,
  models,
  token,
}: {
  hasSetupBilling: boolean;
  hasInvitedOrgMembers: boolean;
  hasMadeAPIPredictions: boolean;
  hasConnectedSlack: boolean;
  kind: "user" | "organization";
  models: Models;
  token: AccessToken;
}) {
  const [recentModels, suggestedModels] = partition(
    models,
    ({ type }) => type === "recent"
  );

  return (
    <div className="flex flex-col lg:flex-row gap-8">
      <div className="flex-1 min-w-0 flex border border-b-2 border-r8-gray-6 rounded max-w-screen-lg flex-col">
        <div className="space-y-8 border-b p-8">
          <div className="flex-shrink-0 size-24 p-4 overflow-hidden relative bg-gradient-to-br from-branding-blush to-branding-red aspect-square rounded-full shadow-xl shadow-branding-blushDarker/50">
            <div className="absolute inset-0 w-full h-full z-[1] opacity-30">
              <BgGoo speed={0.8} resolution={2.0} depth={4} />
            </div>
            <HandWaving className="w-full h-full text-r8-red-10 flex-shrink-0 text-white relative z-[1] -top-0.5" />
          </div>
          <div className="space-y-2">
            <h2 className="text-r8-2xl font-semibold mb-0">
              Welcome to Replicate!
            </h2>
            <p className="text-r8-gray-11 max-w-2xl">
              Follow these steps to make the most out of your experience. Give
              us a shout on <a href="https://discord.gg/replicate">Discord</a>{" "}
              or <a href="https://x.com/replicate">X</a> if you get stuck along
              the way!
            </p>
          </div>
        </div>
        <ul className="space-y-8 max-w-xl p-8">
          <OnboardingStep
            heading="Create an account"
            description={
              <div>
                <div className="space-y-1 text-r8-sm">
                  <p>
                    You can{" "}
                    <a href={`${route("account_settings")}`}>
                      manage your account
                    </a>{" "}
                    to update your email, billing information, API tokens, and
                    more.
                  </p>
                </div>
              </div>
            }
            completed={true}
          />
          <OnboardingStep
            heading="Set up billing"
            description={
              <div>
                <div className="space-y-1 text-r8-sm">
                  <p>
                    You can sometimes try{" "}
                    <a href={`${route("explore")}#featured-models`}>
                      featured models
                    </a>{" "}
                    out on Replicate for free, but usually we’ll ask you to set
                    up billing.
                  </p>
                  <p>
                    Some features are only available to customers with billing
                    set up.
                  </p>
                </div>
                {hasSetupBilling ? null : (
                  <div className="mt-3">
                    <Button
                      size="sm"
                      // biome-ignore lint/a11y/useAnchorContent: <explanation>
                      render={<a href={route("account_billing_settings")} />}
                    >
                      Add payment method
                    </Button>
                  </div>
                )}
              </div>
            }
            completed={hasSetupBilling}
          />
          <OnboardingStep
            heading="Run AI with an API"
            description={
              <div>
                <div className="text-r8-sm space-y-1">
                  <p>
                    Our HTTP API can be used with any programming language, but
                    there are also client libraries for Python, JavaScript, and
                    other languages that make it easier to use the API.
                  </p>
                  <p>
                    This step will be complete once you've run a model using the
                    API.
                  </p>
                </div>
                <div className="mt-3 flex gap-3">
                  <RunModelDialog token={token} models={models} />
                  <Button
                    size="sm"
                    variant="outlined"
                    // biome-ignore lint/a11y/useAnchorContent: content is injected
                    render={<a href="https://replicate.com/docs" />}
                  >
                    Read API docs
                  </Button>
                </div>
              </div>
            }
            completed={hasMadeAPIPredictions}
          />
          {kind === "organization" && (
            <OnboardingStep
              heading="Invite members to your organization"
              description={
                <div>
                  <div className="space-y-1 text-r8-sm">
                    <p>
                      Organizations let you share access to models, API tokens,
                      billing, dashboards, and more.{" "}
                    </p>
                  </div>

                  <div className="mt-3">
                    <Button
                      variant="outlined"
                      size="sm"
                      // biome-ignore lint/a11y/useAnchorContent: <explanation>
                      render={<a href={route("account_members_settings")} />}
                    >
                      {hasInvitedOrgMembers
                        ? "Manage members"
                        : "Invite members"}
                    </Button>
                  </div>
                </div>
              }
              completed={hasInvitedOrgMembers}
            />
          )}
        </ul>
      </div>
      {recentModels.length > 0 || suggestedModels.length > 0 ? (
        <div className="flex-1 min-w-0 space-y-6">
          {recentModels.length > 0 ? (
            <div className="space-y-6">
              <p className="text-r8-xl font-heading">Recently viewed models</p>
              <RecentlyViewedModels models={recentModels} />
            </div>
          ) : null}
          {suggestedModels.length > 0 ? (
            <div className="space-y-6">
              <p className="text-r8-xl font-heading">Suggested models</p>
              <RecentlyViewedModels models={suggestedModels} />
            </div>
          ) : null}
          <div className="mt-3">
            <Button
              size="sm"
              // biome-ignore lint/a11y/useAnchorContent: <explanation>
              render={<a href={route("explore")} />}
              endIcon={<CaretRight />}
            >
              Explore more
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function OnboardingStep({
  completed,
  heading,
  description,
}: {
  completed: boolean;
  heading: React.ReactNode;
  description: React.ReactNode;
}) {
  return (
    <div className="flex gap-4 no-underline no-focus">
      <div className="flex-shrink-0">
        {completed ? (
          <div className="w-8 h-8 bg-r8-green-10 rounded-full flex items-center justify-center text-white text-r8-lg border border-r8-green-11">
            <Check weight="bold" />
          </div>
        ) : (
          <div className="w-8 h-8 bg-r8-gray-3 border border-r8-gray-8 rounded-full" />
        )}
      </div>
      <div className="flex-1">
        <h3
          className={`text-r8-xl font-semibold leading-tight font-heading mb-1.5 ${
            completed ? "text-r8-gray-11" : ""
          }`}
        >
          {heading}
        </h3>
        <div className={completed ? "text-r8-gray-11" : ""}>{description}</div>
      </div>
    </div>
  );
}

function RecentlyViewedModels({
  models,
}: {
  models: Models;
}) {
  return (
    <ul className="space-y-4 w-full mt-3">
      {models.map((m) => {
        const version = m.version;
        const model = version._extras.model;
        return (
          <li className="" key={version.id}>
            <a className="block focus:ring no-underline" href={model.url}>
              <div className="border border-r8-gray-8 hover:border-r8-gray-12 bg-r8-gray-1 flex">
                <div className="aspect-square flex-shrink-0 w-32 relative">
                  {model.cover_image_url ? (
                    <img
                      className="absolute inset-0 w-full h-full object-cover"
                      src={model.cover_image_url}
                    />
                  ) : (
                    <div className="bg-r8-gray-4 flex items-center justify-center h-full">
                      <div className="w-7 h-7 text-r8-gray-11">
                        <LogoShorthand size="100%" />
                      </div>
                    </div>
                  )}
                </div>
                <div className="py-4 px-6 flex-1 space-y-2">
                  <p className="font-heading text-r8-xl">
                    <span className="text-r8-gray-11">{model.owner}</span>
                    <span className="text-r8-gray-11 mx-1">/</span>
                    <span className="text-r8-gray-12 font-semibold">
                      {model.name}
                    </span>
                  </p>
                  <p className="line-clamp-3 text-r8-gray-12 text-r8-sm">
                    {model.description}
                  </p>
                </div>
              </div>
            </a>
          </li>
        );
      })}
    </ul>
  );
}

function RunModelDialog({
  models,
  token,
}: {
  models: Models;
  token: AccessToken;
}) {
  const [selectedModelIndex, setSelectedModelIndex] = useState(0);
  const isDesktopScreen = useMediaMatch("(min-width: 1024px)");

  if (models.length === 0) {
    return (
      // biome-ignore lint/a11y/useAnchorContent: content is injected
      <Button size="sm" render={<a href={route("explore")} />}>
        Explore models to get started
      </Button>
    );
  }

  const selectedModel = models[selectedModelIndex];
  const runInput = selectedModel.example_inputs ?? {};
  const selectedVersion = selectedModel.version;

  return (
    <DialogProvider>
      <DialogDisclosure render={<Button size="sm" />}>
        Run a model
      </DialogDisclosure>
      <Dialog
        variant="drawer"
        side={isDesktopScreen ? "right" : "bottom"}
        className="lg:w-[38rem] h-full overflow-auto"
      >
        <div>
          <div className="p-4 bg-r8-gray-3">
            <div className="flex items-center justify-between mb-2">
              <Label className="block" htmlFor="model">
                Select model
              </Label>

              <DialogDismiss>
                <X size={18} weight="bold" />
              </DialogDismiss>
            </div>
            <NativeSelect
              name="model"
              id="model"
              size="sm"
              onChange={(e) => {
                setSelectedModelIndex(Number.parseInt(e.target.value));
              }}
            >
              {models.map((model, index) => (
                <option
                  key={`${model.model.owner}/${model.model.name}`}
                  value={index}
                >
                  {model.model.owner}/{model.model.name}
                </option>
              ))}
            </NativeSelect>
          </div>
          <div>
            <TabProvider>
              <TabList size="sm" className="px-6">
                <Tab>Node.js</Tab>
                <Tab>Python</Tab>
                <Tab>HTTP</Tab>
              </TabList>
              <TabPanel className="p-6">
                {selectedModel.is_official_model ? (
                  <RunWith
                    context={RunWithContext.NodeJS}
                    input={runInput}
                    model={selectedModel.model}
                    token={token}
                    usesVersionlessApi
                    version={selectedVersion}
                  />
                ) : (
                  <RunWith
                    context={RunWithContext.NodeJS}
                    input={runInput}
                    token={token}
                    usesVersionlessApi={false}
                    version={selectedVersion}
                  />
                )}
              </TabPanel>
              <TabPanel className="p-6">
                {selectedModel.is_official_model ? (
                  <RunWith
                    context={RunWithContext.Python}
                    input={runInput}
                    model={selectedModel.model}
                    token={token}
                    usesVersionlessApi
                    version={selectedVersion}
                  />
                ) : (
                  <RunWith
                    context={RunWithContext.Python}
                    input={runInput}
                    token={token}
                    usesVersionlessApi={false}
                    version={selectedVersion}
                  />
                )}
              </TabPanel>
              <TabPanel className="p-6">
                {selectedModel.is_official_model ? (
                  <RunWith
                    context={RunWithContext.HTTP}
                    input={runInput}
                    model={selectedModel.model}
                    token={token}
                    usesVersionlessApi
                    version={selectedVersion}
                  />
                ) : (
                  <RunWith
                    context={RunWithContext.HTTP}
                    input={runInput}
                    token={token}
                    usesVersionlessApi={false}
                    version={selectedVersion}
                  />
                )}
              </TabPanel>
            </TabProvider>
          </div>
        </div>
      </Dialog>
    </DialogProvider>
  );
}
