import { Portal } from "@ariakit/react";
import { UserPlus } from "@phosphor-icons/react";
import { Button } from "@replicate/ui";
import type { ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { InviteToOrganizationDialog } from "./invite-to-organization-dialog";

export default function Invite({
  organizationName,
}: {
  organizationName: string;
}): ReactNode {
  return (
    <>
      <Portal>
        <Toaster />
      </Portal>
      <InviteToOrganizationDialog
        organizationName={organizationName}
        disclosure={
          <Button startIcon={<UserPlus weight="bold" />}>
            Invite a new member to {organizationName}
          </Button>
        }
      />
    </>
  );
}
