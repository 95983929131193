import llamaTokenizer from "llama-tokenizer-js";
import { match } from "ts-pattern";
import { formatCurrency, formatNumber } from "../../intl_helpers";
import type {
  CogInputPropertySchema,
  CogInputSchema,
  Deployment,
  Features,
  Model,
  OpenapiSchema,
  PlaygroundPermissions,
  Token,
  Version,
} from "../../types";

export function getSortedInputProperties(
  properties: OpenapiSchema["components"]["schemas"]["Input"]["properties"]
) {
  return Object.keys(properties).sort((a, b) => {
    return properties[a]["x-order"] - properties[b]["x-order"];
  });
}

export function isImageUrl(url: string) {
  return /\.(jpeg|jpg|gif|png|webp)$/.test(url);
}

export function isImageMimeType(mimeType: string) {
  return /^image\/(jpeg|jpg|gif|png|x-png|webp)$/.test(mimeType);
}

export function isAudioUrl(url: string) {
  return /\.(mp3|wav|ogg|m4a)$/.test(url);
}

export function isAudioMimeType(mimeType: string) {
  return /^audio\/(mpeg|wav|ogg|mp4)$/.test(mimeType);
}

export function basename(path: string) {
  return path.split(/[\\/]/).pop();
}

// Copied from https://npm.im/is-number
export function isNumber(num: any) {
  if (typeof num === "number") {
    return num - num === 0;
  }
  if (typeof num === "string" && num.trim() !== "") {
    return Number.isFinite ? Number.isFinite(+num) : Number.isFinite(+num);
  }
  return false;
}

export function nullishInputPredicate([key, value]: [string, any]) {
  return value != null;
}

export function safetyCheckerPredicate([key, _]: [string, any]) {
  return key !== "disable_safety_checker";
}

function validSchemaInputPredicate(schema: CogInputSchema["properties"]) {
  return ([key, _]: [string, any]) => {
    return key in schema;
  };
}

function hideDefaultValuesPredicate(schema: CogInputSchema["properties"]) {
  return ([key, value]: [string, any]) => {
    const defaultValue = schema[key].default;
    if (typeof defaultValue === "undefined") {
      return true;
    }
    if (Array.isArray(defaultValue)) {
      return !value.every((v: any, i: number) => v === defaultValue[i]);
    }
    return value !== defaultValue;
  };
}

/**
 *
 * Helper function for handling array input values.
 * This is necessary because react-hook-form's useFieldArray hook
 * transforms empty (read: null) array field values into an empty array.
 * This is particularly problematic when the default value for a field isn't
 * an empty array, but the user has cleared the field in the UI.
 */
const emptyArrayPredicate = (
  [key, value]: [string, any],
  schema: CogInputSchema["properties"]
) => {
  // We only care about array-like values in this predicate.
  if (!Array.isArray(value)) {
    return true;
  }

  // If we have a value, we want to keep it. No further checks are necessary.
  if (value.length > 0) {
    return true;
  }

  const item = schema[key];

  // It's possible that the input provided doesn't match
  // the schema. In that case, let's filter out the value.
  if (!item) {
    return false;
  }

  // The only condition under which we want to keep an empty array
  // is when the default value for the property is also an empty array.
  const defaultValue = item.default;
  const isArrayDefaultValue = Array.isArray(defaultValue);

  if (isArrayDefaultValue && defaultValue.length === 0) {
    return true;
  }

  return false;
};

export function cleanInputForSubmission(
  input: Record<string, any>,
  schema: CogInputSchema["properties"],
  {
    skipFiles,
    hideDefaultValues,
    alwaysInclude,
  }: {
    skipFiles?: boolean;
    hideDefaultValues?: boolean;
    alwaysInclude?: string[];
  } = {}
) {
  return Object.fromEntries(
    Object.entries(input)
      .filter(([key, value]) => {
        if (alwaysInclude?.includes(key)) {
          return true;
        }
        return nullishInputPredicate([key, value]);
      })
      .filter((item) => emptyArrayPredicate(item, schema))
      .filter(safetyCheckerPredicate) // Prevent users from disabling the safety checker
      .filter(validSchemaInputPredicate(schema))
      .filter((value) => {
        if (hideDefaultValues) {
          return hideDefaultValuesPredicate(schema)(value);
        }
        return true;
      })
      .filter(([_, value]) => {
        if (skipFiles) {
          return !(value instanceof File);
        }
        return true;
      })
  );
}

export function addDefaultExampleType(
  input: Record<string, any>,
  schema: CogInputSchema["properties"]
) {
  return Object.fromEntries(
    Object.entries(input).map(([key, value]) => {
      if (value == null) {
        const item: CogInputPropertySchema = schema[key];
        const defaultValue = match(item)
          .with({ type: "string", format: "uri" }, () => "https://")
          .with({ type: "string" }, () => "")
          .otherwise(() => null);
        return [key, defaultValue];
      }
      return [key, value];
    })
  );
}

/**
 * A helper function to handle special cases,
 * like string[] input types.
 */
export function processInputForSubmission(
  input: Record<string, any>,
  schema: CogInputSchema["properties"]
) {
  const processed: Record<string, any> = {};

  for (const [key, value] of Object.entries(input)) {
    const inputSchema = schema[key];

    // We have a special component in APIPlayground for handling string[] inputs.
    // It uses react-hook-form and converts string[] to { value: string }[].
    // On the way out, we need to convert any values back to string[].
    const isArrayOfStringType =
      "type" in inputSchema &&
      inputSchema.type === "array" &&
      inputSchema.items.type === "string";

    // We have a special component in APIPlayground for handling number[] inputs.
    // It's a string, so on the way out, we need to convert to number[].
    const isArrayOfNumberType =
      "type" in inputSchema &&
      inputSchema.type === "array" &&
      (inputSchema.items.type === "number" ||
        inputSchema.items.type === "integer");

    // Handle converting between { value: string }[] and a string[]
    if (isArrayOfStringType && Array.isArray(value)) {
      const filtered = value.filter((v) => v != null);
      const values = filtered.map((v) => {
        if (typeof v === "object" && "value" in v) {
          return v.value;
        }
        return v;
      });
      processed[key] = values;

      // Handle converting between a number[] and string
    } else if (isArrayOfNumberType && !Array.isArray(value)) {
      if (!Number.isNaN(Number(value))) {
        processed[key] = [Number(value)];
      } else {
        processed[key] = value.split(",").map((num: string) => Number(num));
      }
    } else {
      processed[key] = value;
    }
  }
  return processed;
}

export type RenderMode = "default" | "before-after-slider" | "goo-shader";

export function getRenderMode({
  features,
}: {
  features: Features;
}): RenderMode {
  if (features.show_goo_shader_output) {
    return "goo-shader";
  }

  if (features.show_before_after_slider_output) {
    return "before-after-slider";
  }

  return "default";
}

export interface ElementVisibility {
  tweakButton?: boolean;
}

export function getElementVisibility({
  permissions,
  deployment,
}: {
  permissions: PlaygroundPermissions;
  deployment?: Deployment;
  version?: Version;
  model?: Model;
}): ElementVisibility {
  return {
    tweakButton: permissions.tweak && !deployment,
  };
}

export const formatTokenPrice = (price: number) => {
  return formatCurrency(price, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  });
};

const formatTokenCount = (count: number) => {
  return formatNumber(count, {
    maximumSignificantDigits: 2,
    roundingMode: "trunc",
  });
};

export function humanizeTokenCount(count: number): string {
  if (Number.isNaN(count)) {
    return "—";
  }

  if (!Number.isFinite(count)) {
    return "∞";
  }

  const powers = [
    [9, "B"],
    [6, "M"],
    [3, "K"],
  ];

  for (const [power, suffix] of powers) {
    const n = 10 ** Number(power);
    if (count >= n) {
      return formatTokenCount(count / n) + suffix;
    }
  }

  return formatTokenCount(count);
}

export const tokenize = (text: string, hideSpecialTokens = true): Token[] => {
  const tokens: Token[] = [];
  for (const [index, id] of llamaTokenizer.encode(text).entries()) {
    if (id < 3) {
      if (hideSpecialTokens) {
        continue;
      }

      if (id === 0) {
        tokens.push([id, "<unk>"]);
      } else if (id === 1) {
        tokens.push([id, "<s>"]);
      } else if (id === 2) {
        tokens.push([id, "</s>"]);
      }
    } else if (id === 13) {
      tokens.push([id, "\\n"]);
    } else if (id <= 258) {
      tokens.push([id, llamaTokenizer.vocabById[id]]);
    } else {
      const add_preceding_space = index !== 1;
      const add_bos_token = false;
      tokens.push([
        id,
        llamaTokenizer.decode([id], add_bos_token, !add_preceding_space), // counterintuitively, add_preceding_space removes space added when parameter is set to true when calling encode
      ]);
    }
  }

  return tokens;
};
