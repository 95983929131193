import { LightbulbFilament } from "@phosphor-icons/react";
import { Banner, Tab, TabList, TabPanel, TabProvider } from "@replicate/ui";
import {
  useMutation,
  useQueryClient,
  type UseQueryResult,
} from "@tanstack/react-query";
import { isObject } from "lodash-es";
import { useState } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormProvider } from "react-hook-form";
import { Toaster } from "react-hot-toast";
import { trackEvent } from "../../analytics";
import { useQueryParam } from "../../hooks";
import { getInputSchema } from "../../schema";
import type {
  AccessToken,
  CogInputSchema,
  Deployment,
  ErrorWithJSON,
  Features,
  Model,
  PlaygroundPermissions,
  Prediction,
  Version,
} from "../../types";
import {
  createDeploymentPrediction,
  createOfficialModelPrediction,
  createVersionPrediction,
} from "./api";
import { TERMINAL_PREDICTION_STATUSES } from "./constants";
import {
  PlaygroundContext,
  usePlaygroundContext,
  type PlaygroundContextValue,
} from "./context";
import { FeaturedInputs } from "./featured-inputs";
import {
  queryKeys,
  useDockerImage,
  useInputHints,
  useInputHintsOfficialModel,
  useInputsForm,
  usePrediction,
} from "./hooks";
import { InitialOutput } from "./initial-output";
import { InputForm, type TextInputOptions } from "./input-form";
import { InputFormFooter } from "./input-form-footer";
import { InputOutputGrid } from "./input-output-grid";
import JSONEditor from "./json-editor";
import { PredictionDetailOutput } from "./prediction-detail-output";
import { PredictionErrorState } from "./prediction-error-state";
import { PredictionLoadingState } from "./prediction-loading-state";
import { RunWithAPIPlayground, RunWithContext } from "./run-with";
import { RunWithNPX } from "./run-with-npx";
import { SetupFailureCheck } from "./setup-failure-check";
import {
  cleanInputForSubmission,
  getElementVisibility,
  getRenderMode,
  processInputForSubmission,
} from "./util";

interface InitialPrediction {
  prediction: Prediction;
  version: Version;
}

interface VersionAPIPlaygroundProps {
  features: Features;
  hideAdvancedInputs?: boolean;
  hideVersionMismatchWarning?: boolean;
  initialPrediction: Prediction | null;
  initialPredictionVersion: Version | null;
  isAuthenticated: boolean;
  model?: never;
  modelInputSettings?: { hidden: string[] };
  modelStatus?: "online" | "offline";
  permissions: PlaygroundPermissions;
  setPredictionIdInUrl?: boolean;
  token?: AccessToken | null;
  deployment?: never;
  version: Version;
}

type OfficialModelAPIPlaygroundProps = {
  features: Features;
  hideAdvancedInputs?: boolean;
  hideVersionMismatchWarning?: boolean;
  initialPrediction: Prediction | null;
  initialPredictionVersion: Version | null;
  isAuthenticated: boolean;
  model: Model;
  modelInputSettings?: { hidden: string[] };
  modelStatus?: "online" | "offline";
  permissions: PlaygroundPermissions;
  setPredictionIdInUrl?: boolean;
  token?: AccessToken | null;
  deployment?: never;
  version: Version;
};

type DeploymentAPIPlaygroundProps = {
  features: Features;
  hideAdvancedInputs?: boolean;
  hideVersionMismatchWarning?: boolean;
  initialPrediction: Prediction | null;
  initialPredictionVersion: Version | null;
  isAuthenticated: boolean;
  model: Model;
  modelInputSettings?: { hidden: string[] };
  modelStatus?: "online" | "offline";
  permissions: PlaygroundPermissions;
  setPredictionIdInUrl?: boolean;
  token?: AccessToken | null;
  version: Version;
  deployment: Deployment;
};

export default function APIPlayground({
  features,
  hideAdvancedInputs,
  hideVersionMismatchWarning,
  initialPrediction,
  initialPredictionVersion,
  isAuthenticated,
  model,
  modelInputSettings,
  modelStatus,
  permissions,
  setPredictionIdInUrl,
  token,
  version,
  deployment,
}:
  | VersionAPIPlaygroundProps
  | OfficialModelAPIPlaygroundProps
  | DeploymentAPIPlaygroundProps) {
  const queryClient = useQueryClient();

  // By priming the react-query cache with the initial prediction, we can skip
  // the initial loading state (hence hiding the spinner) and go straight to the
  // prediction detail view.
  //
  // Note that unless we adjust the "staleTime" of the query, the prediction
  // will be re-fetched after the initial render, which will result in another
  // background network request without a UI update.
  if (initialPrediction) {
    queryClient.setQueryData<Prediction>(
      queryKeys.predictions.uuid(initialPrediction.id),
      initialPrediction
    );
  }

  const initialPredictionWrapper: InitialPrediction | null =
    initialPrediction && initialPredictionVersion
      ? {
          prediction: initialPrediction,
          version: initialPredictionVersion,
        }
      : null;

  const context: PlaygroundContextValue = {
    elementVisibility: getElementVisibility({
      permissions,
      deployment,
      model,
      version,
    }),
    features,
    hideAdvancedInputs: hideAdvancedInputs ?? false,
    hideVersionMismatchWarning: hideVersionMismatchWarning ?? false,
    initialPredictionVersion,
    isAuthenticated,
    model: model,
    modelStatus: modelStatus ?? null,
    modelInputSettings: modelInputSettings ?? { hidden: [] },
    permissions,
    renderMode: getRenderMode({ features }),
    token: token ?? null,
    version: version,
  };

  const playgroundComponent = () => {
    if (deployment) {
      return (
        <DeploymentPlaygroundForm
          initialPrediction={initialPredictionWrapper}
          deployment={deployment}
        />
      );
    }

    if (model) {
      return (
        <OfficialModelPlaygroundForm
          initialPrediction={initialPredictionWrapper}
          setPredictionIdInUrl={
            isAuthenticated && (setPredictionIdInUrl ?? true)
          }
          model={model}
        />
      );
    }

    return (
      <VersionPlaygroundForm
        initialPrediction={initialPredictionWrapper}
        setPredictionIdInUrl={isAuthenticated && (setPredictionIdInUrl ?? true)}
      />
    );
  };

  return (
    <PlaygroundContext.Provider value={context}>
      {playgroundComponent()}
    </PlaygroundContext.Provider>
  );
}

function DeploymentPlaygroundForm({
  initialPrediction,
  deployment,
}: {
  initialPrediction: InitialPrediction | null;
  deployment: Deployment;
}) {
  const { version } = usePlaygroundContext();
  const inputSchema = getInputSchema(version);
  const {
    query: predictionQuery,
    run: handleSubmit,
    state: submissionState,
  } = useCreateDeploymentPrediction({
    inputSchema,
    deployment,
    version,
  });

  const form = useInputsForm(
    inputSchema.properties,
    initialPrediction?.prediction.input
  );

  const predictionIsRunning = Boolean(
    predictionQuery.data &&
      !TERMINAL_PREDICTION_STATUSES.includes(predictionQuery.data.status)
  );

  return (
    <>
      <InputOutputGrid
        input={
          <PlaygroundFormInput
            form={form}
            isRunning={
              submissionState.status === "pending" || predictionIsRunning
            }
            onSubmit={handleSubmit}
            model={version._extras.model}
            version={version}
            // TODO: Update the code snippets to handle predictions
            // against the deployments API
            showLanguageTabs={false}
          />
        }
        outputIsLoading={
          // Show a spinner if the prediction is in a non-terminal state,
          // or if the prediction is being created.
          predictionIsRunning || submissionState.status === "pending"
        }
        output={
          <PlaygroundFormOutput
            form={form}
            initialPrediction={initialPrediction}
            prediction={predictionQuery.data}
            submissionState={submissionState}
            updateStartedAt={new Date(predictionQuery.dataUpdatedAt)}
            updateState={predictionQuery}
          />
        }
      />
      <Toaster position="bottom-right" />
    </>
  );
}

function OfficialModelPlaygroundForm({
  initialPrediction,
  setPredictionIdInUrl,
  model,
}: {
  initialPrediction: InitialPrediction | null;
  setPredictionIdInUrl: boolean;
  model: Model;
}) {
  const { version } = usePlaygroundContext();
  const inputSchema = getInputSchema(version);
  const {
    query: predictionQuery,
    run: handleSubmit,
    state: submissionState,
  } = useCreateOfficialModelPrediction({
    inputSchema,
    model,
    version,
    setPredictionIdInUrl,
  });

  const form = useInputsForm(
    inputSchema.properties,
    initialPrediction?.prediction.input
  );

  const predictionIsRunning = Boolean(
    predictionQuery.data &&
      !TERMINAL_PREDICTION_STATUSES.includes(predictionQuery.data.status)
  );

  return (
    <>
      <InputOutputGrid
        input={
          <PlaygroundFormInput
            form={form}
            isRunning={
              submissionState.status === "pending" || predictionIsRunning
            }
            isOfficial={true}
            onSubmit={handleSubmit}
            model={model}
            version={version}
          />
        }
        outputIsLoading={
          // Show a spinner if the prediction is in a non-terminal state,
          // or if the prediction is being created.
          predictionIsRunning || submissionState.status === "pending"
        }
        output={
          <PlaygroundFormOutput
            form={form}
            initialPrediction={initialPrediction}
            prediction={predictionQuery.data}
            submissionState={submissionState}
            updateStartedAt={new Date(predictionQuery.dataUpdatedAt)}
            updateState={predictionQuery}
          />
        }
      />
      <Toaster position="bottom-right" />
    </>
  );
}

function VersionPlaygroundForm({
  initialPrediction,
  setPredictionIdInUrl,
}: {
  initialPrediction: InitialPrediction | null;
  setPredictionIdInUrl: boolean;
}) {
  const { version, permissions, modelInputSettings } = usePlaygroundContext();
  const inputSchema = getInputSchema(version);
  const {
    query: predictionQuery,
    run: handleSubmit,
    state: submissionState,
  } = useCreateVersionPrediction({
    version,
    setPredictionIdInUrl,
    inputSchema,
  });

  const form = useInputsForm(
    inputSchema.properties,
    initialPrediction?.prediction.input
  );

  const predictionIsRunning = Boolean(
    predictionQuery.data &&
      !TERMINAL_PREDICTION_STATUSES.includes(predictionQuery.data.status)
  );

  const canEditFeaturedInputs =
    modelInputSettings && permissions.edit_featured_inputs;

  return (
    <>
      <InputOutputGrid
        inputHeadingExtra={
          canEditFeaturedInputs ? (
            <FeaturedInputs type="prediction" schema={inputSchema} />
          ) : null
        }
        input={
          <PlaygroundFormInput
            form={form}
            isRunning={
              submissionState.status === "pending" || predictionIsRunning
            }
            onSubmit={handleSubmit}
            version={version}
          />
        }
        outputIsLoading={
          // Show a spinner if the prediction is in a non-terminal state,
          // or if the prediction is being created.
          predictionIsRunning || submissionState.status === "pending"
        }
        output={
          <PlaygroundFormOutput
            form={form}
            initialPrediction={initialPrediction}
            prediction={predictionQuery.data}
            submissionState={submissionState}
            updateStartedAt={new Date(predictionQuery.dataUpdatedAt)}
            updateState={predictionQuery}
          />
        }
      />
      <Toaster position="bottom-right" />
    </>
  );
}

type PlaygroundFormState =
  | {
      error: null;
      status: "idle" | "pending" | "success";
    }
  | {
      error: ErrorWithJSON;
      status: "error";
    };

function useCreateDeploymentPrediction({
  inputSchema,
  deployment,
  version,
}: {
  inputSchema: CogInputSchema | null;
  deployment: Deployment;
  version: Version;
}): {
  query: UseQueryResult<Prediction, ErrorWithJSON>;
  run: (input: FieldValues) => void;
  state: PlaygroundFormState;
} {
  const mutation = useMutation<
    Awaited<ReturnType<typeof createDeploymentPrediction>>,
    ErrorWithJSON,
    Parameters<typeof createDeploymentPrediction>[0]
  >({
    mutationFn: createDeploymentPrediction,
  });

  // Used to check on the status of the newly created prediction. We want to be
  // able to disable the form while the prediction is in a non-terminal state.
  const query = usePrediction({
    uuid: mutation.data?.id ?? null,
  });

  function run(input: FieldValues) {
    const cleanInput = cleanInputForSubmission(
      input,
      getInputSchema(version).properties
    );

    const processedInput = processInputForSubmission(
      cleanInput,
      getInputSchema(version).properties
    );

    mutation.mutate({
      input: processedInput,
      inputSchema,
      deployment,
    });
  }

  return {
    query,
    run,
    state: mutation,
  };
}

function useCreateOfficialModelPrediction({
  inputSchema,
  model,
  setPredictionIdInUrl,
  version,
}: {
  inputSchema: CogInputSchema | null;
  model: Model;
  setPredictionIdInUrl: boolean;
  version: Version;
}): {
  query: UseQueryResult<Prediction, ErrorWithJSON>;
  run: (input: FieldValues) => void;
  state: PlaygroundFormState;
} {
  const mutation = useMutation<
    Awaited<ReturnType<typeof createOfficialModelPrediction>>,
    ErrorWithJSON,
    Parameters<typeof createOfficialModelPrediction>[0]
  >({
    mutationFn: createOfficialModelPrediction,
    onSuccess(data) {
      if (setPredictionIdInUrl) {
        const url = new URL(window.location.href);
        url.searchParams.set("prediction", data.id);
        window.history.pushState({}, "", url.toString());
      }
    },
  });

  // Used to check on the status of the newly created prediction. We want to be
  // able to disable the form while the prediction is in a non-terminal state.
  const query = usePrediction({
    uuid: mutation.data?.id ?? null,
  });

  function run(input: FieldValues) {
    const cleanInput = cleanInputForSubmission(
      input,
      getInputSchema(version).properties
    );

    const processedInput = processInputForSubmission(
      cleanInput,
      getInputSchema(version).properties
    );

    // FIXME: Official language models using MLC don't currently support `top_k`
    // parameter.
    const { top_k, ...cleanFilteredInput } = processedInput;

    mutation.mutate({
      input: cleanFilteredInput,
      inputSchema,
      model,
    });
  }

  return {
    query,
    run,
    state: mutation,
  };
}

function useCreateVersionPrediction({
  inputSchema,
  setPredictionIdInUrl,
  version,
}: {
  inputSchema: CogInputSchema | null;
  setPredictionIdInUrl: boolean;
  version: Version;
}): {
  query: UseQueryResult<Prediction, ErrorWithJSON>;
  run: (input: FieldValues) => void;
  state: PlaygroundFormState;
} {
  const mutation = useMutation<
    Awaited<ReturnType<typeof createVersionPrediction>>,
    ErrorWithJSON,
    Parameters<typeof createVersionPrediction>[0]
  >({
    mutationFn: createVersionPrediction,
    onSuccess(data) {
      if (setPredictionIdInUrl) {
        const url = new URL(window.location.href);
        url.searchParams.set("prediction", data.id);
        window.history.pushState({}, "", url.toString());
      }
    },
  });

  // Used to check on the status of the newly created prediction. We want to be
  // able to disable the form while the prediction is in a non-terminal state.
  const query = usePrediction({
    uuid: mutation.data?.id ?? null,
  });

  function run(input: FieldValues) {
    const cleanInput = cleanInputForSubmission(
      input,
      getInputSchema(version).properties
    );

    const processedInput = processInputForSubmission(
      cleanInput,
      getInputSchema(version).properties
    );

    mutation.mutate({
      input: processedInput,
      inputSchema,
      version,
    });
  }

  return {
    query,
    run,
    state: mutation,
  };
}

function PlaygroundFormInput({
  form,
  isRunning,
  onSubmit,
  model,
  version,
  showLanguageTabs = true,
  isOfficial = false,
}: {
  form: UseFormReturn;
  isRunning: boolean;
  onSubmit: (input: FieldValues) => void;
  /** Presence indicates this is an official model */
  model?: Model;
  version: Version;
  showLanguageTabs?: boolean;
  isOfficial?: boolean;
}) {
  const {
    isAuthenticated,
    hideAdvancedInputs,
    permissions,
    token,
    features,
    modelStatus,
    modelInputSettings,
  } = usePlaygroundContext();
  const dockerImageQuery = isOfficial
    ? { isSuccess: false, data: undefined }
    : useDockerImage(version);
  const inputHintsQuery =
    isOfficial && model
      ? useInputHintsOfficialModel(model)
      : useInputHints(version);

  const [formSubmitLabel, setFormSubmitLabel] = useState(
    modelStatus === "offline" ? "Boot + Run" : "Run"
  );

  const DEFAULT_TAB = "form";
  const [activeTabId, setActiveTabId] = useQueryParam("input", DEFAULT_TAB);

  const isDisabled = !permissions.run;

  const inputSchema = getInputSchema(version);

  const wrappedOnSubmit = (input: FieldValues) => {
    if (modelStatus === "offline") {
      setFormSubmitLabel("Run");
    }
    onSubmit(input);
  };

  /**
   * When switching to the JSON tab, we need to check if there are any files
   * uploaded in the form. If there are, we need to prompt the user to confirm
   * that they want to switch to the JSON tab, as this will discard any files
   * they've uploaded. From the JSON tab they can specify file URLs instead.
   */
  const handleJsonTabChange = () => {
    const formValues = form.getValues();
    const fieldsWithFileValues = Object.keys(formValues).filter((key) => {
      const value = formValues[key];
      return Array.isArray(value)
        ? value.some((v) => {
            if (isObject(v) && "value" in v) {
              return v.value instanceof File;
            }
            return v instanceof File;
          })
        : value instanceof File;
    });

    if (fieldsWithFileValues.length === 0) {
      setActiveTabId("json");
      return;
    }

    const confirmRemoveFiles = window.confirm(
      "Switching to the JSON tab will discard any files you've uploaded. File URLs will be preserved. Are you sure you want to switch?"
    );

    if (confirmRemoveFiles) {
      for (const fieldName of fieldsWithFileValues) {
        form.setValue(fieldName, null);
      }
      setActiveTabId("json");
      return;
    }

    return;
  };

  const textInputOptions: TextInputOptions = {};
  const triggerWord = inputHintsQuery.data?.trigger_word;
  if (triggerWord) {
    textInputOptions.tiptap = {
      wordToHighlight: triggerWord,
    };
  }

  return (
    <TabProvider
      defaultActiveId={DEFAULT_TAB}
      selectedId={activeTabId ?? DEFAULT_TAB}
      setSelectedId={(id) => {
        trackEvent(`tab_pred_input_${id}`, {
          ...(model && { model: `${model.owner}/${model.name}` }),
          version: version.id,
        });

        if (id === "json") {
          handleJsonTabChange();
        } else {
          setActiveTabId(id ?? undefined);
        }
      }}
    >
      <FormProvider {...form}>
        <TabList responsive size="sm">
          <Tab id="form">Form</Tab>
          {showLanguageTabs ? (
            <>
              <Tab id="json">JSON</Tab>
              <Tab id="nodejs">Node.js</Tab>
              <Tab id="python">Python</Tab>
              <Tab id="http">HTTP</Tab>
              {dockerImageQuery.isSuccess && (
                <>
                  <Tab id="cog">Cog</Tab>
                  {version._extras.model.visibility === "public" && (
                    <Tab id="docker">Docker</Tab>
                  )}
                </>
              )}
            </>
          ) : null}
        </TabList>
        <TabPanel className="py-4" tabId="form">
          <InputForm
            disabled={isDisabled}
            hiddenFields={modelInputSettings?.hidden ?? []}
            hideAdvancedInputs={hideAdvancedInputs}
            onSubmit={wrappedOnSubmit}
            properties={inputSchema.properties}
            required={inputSchema.required ?? []}
            advanced={inputSchema.advanced}
            itemClass="py-2.5"
            textInputOptions={textInputOptions}
          >
            {triggerWord ? (
              <div className="mb-4">
                <Banner
                  condensed
                  severity="info"
                  icon={
                    <LightbulbFilament
                      weight="duotone"
                      className="top-0.5 relative"
                    />
                  }
                  description={
                    <p>
                      The trigger word for this model is{" "}
                      <span className="font-semibold">{triggerWord}</span>. Be
                      sure to include it in your prompt.
                    </p>
                  }
                />
              </div>
            ) : null}
          </InputForm>
        </TabPanel>
        {showLanguageTabs ? (
          <>
            <TabPanel className="py-4" tabId="nodejs">
              <div className="space-y-4">
                <RunWithNPX model={model} version={version} />
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-r8-gray-6" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white dark:bg-r8-gray-1 px-2 text-sm text-r8-gray-11">
                      or set up a project from scratch
                    </span>
                  </div>
                </div>
                {model == null ? (
                  <RunWithAPIPlayground
                    context={RunWithContext.NodeJS}
                    token={token}
                    usesVersionlessApi={false}
                    version={version}
                  />
                ) : (
                  <RunWithAPIPlayground
                    context={RunWithContext.NodeJS}
                    model={model}
                    token={token}
                    usesVersionlessApi
                    version={version}
                  />
                )}
              </div>
            </TabPanel>
            {activeTabId === "json" ? (
              <TabPanel className="py-4" tabId="json">
                <JSONEditor schema={inputSchema.properties} />
              </TabPanel>
            ) : null}
            <TabPanel className="py-4" tabId="python">
              {model == null ? (
                <RunWithAPIPlayground
                  context={RunWithContext.Python}
                  token={token}
                  usesVersionlessApi={false}
                  version={version}
                />
              ) : (
                <RunWithAPIPlayground
                  context={RunWithContext.Python}
                  model={model}
                  token={token}
                  usesVersionlessApi
                  version={version}
                />
              )}
            </TabPanel>
            <TabPanel className="py-4" tabId="http">
              {model == null ? (
                <RunWithAPIPlayground
                  context={RunWithContext.HTTP}
                  token={token}
                  usesVersionlessApi={false}
                  version={version}
                />
              ) : (
                <RunWithAPIPlayground
                  context={RunWithContext.HTTP}
                  model={model}
                  token={token}
                  usesVersionlessApi
                  version={version}
                />
              )}
            </TabPanel>
            {dockerImageQuery.isSuccess && dockerImageQuery.data && (
              <>
                <TabPanel className="py-4" tabId="cog">
                  {model == null ? (
                    <RunWithAPIPlayground
                      context={RunWithContext.Cog}
                      token={token}
                      dockerImageName={dockerImageQuery.data.docker_image_name}
                      usesVersionlessApi={false}
                      version={version}
                    />
                  ) : (
                    <RunWithAPIPlayground
                      context={RunWithContext.Cog}
                      dockerImageName={dockerImageQuery.data.docker_image_name}
                      model={model}
                      token={token}
                      usesVersionlessApi
                      version={version}
                    />
                  )}
                </TabPanel>
                {version._extras.model.visibility === "public" && (
                  <TabPanel className="py-4" tabId="docker">
                    {model == null ? (
                      <RunWithAPIPlayground
                        context={RunWithContext.Docker}
                        dockerImageName={
                          dockerImageQuery.data.docker_image_name
                        }
                        token={token}
                        usesVersionlessApi={false}
                        version={version}
                      />
                    ) : (
                      <RunWithAPIPlayground
                        context={RunWithContext.Docker}
                        dockerImageName={
                          dockerImageQuery.data.docker_image_name
                        }
                        model={model}
                        token={token}
                        usesVersionlessApi
                        version={version}
                      />
                    )}
                  </TabPanel>
                )}
              </>
            )}
          </>
        ) : null}
        <InputFormFooter
          disabled={isDisabled}
          isAuthenticated={isAuthenticated}
          isLoading={isRunning}
          onReset={form.reset}
          submitLabel={formSubmitLabel}
          isOfficial={isOfficial}
        />
      </FormProvider>
    </TabProvider>
  );
}

function PlaygroundFormOutput({
  form,
  initialPrediction,
  prediction,
  submissionState,
  updateStartedAt,
  updateState,
}: {
  form: UseFormReturn;
  initialPrediction: InitialPrediction | null;
  prediction: Prediction | undefined;
  submissionState: PlaygroundFormState;
  updateStartedAt: Date;
  updateState: PlaygroundFormState;
}) {
  // Confusingly, there are two "versions" here:
  //
  // 1. The version of the model we're currently looking at, as represented by
  //    the "version" prop.
  // 2. The version of the model that the prediction (if it exists) was created
  //    with, as represented by "prediction._extras.version". This might be
  //    different if we have an initial prediction from a different version of
  //    the model (such as when the default example was created on a different
  //    version).
  //
  // For the input form, we _always_ want to use the version of the model we're
  // currently looking at. For the output, we want to use the version of the
  // model that the prediction (if it exists) was created with.
  const { features, permissions, hideVersionMismatchWarning, version } =
    usePlaygroundContext();

  const initialPredictionVersionWithFallback = initialPrediction
    ? initialPrediction.version
    : version;

  return (
    <div className="space-y-4">
      {permissions.debug ? <SetupFailureCheck versionId={version.id} /> : null}

      {form.formState.isSubmitted ? (
        // Once the form has been submitted, we want the output to be that
        // of the prediction that was just created via the webform.
        // This block handles the loading, error, and success states for that,
        // and passes down the running prediction query to the PostSubmitOutput component.
        <>
          {submissionState.status === "error" && (
            <PredictionErrorState
              error={submissionState.error}
              fallback="Failed to run the model."
            />
          )}
          {submissionState.status === "pending" &&
            (features.show_token_count ? null : (
              <PredictionLoadingState>Starting...</PredictionLoadingState>
            ))}
          {submissionState.status === "success" && (
            <PostSubmitOutput
              prediction={prediction}
              updateStartedAt={updateStartedAt}
              updateState={updateState}
            />
          )}
        </>
      ) : (
        // Until such point, we want the output to be that of the initial prediction,
        // if it exists, and this component handles the loading, error, and success states
        // as well as refetching logic for the initial prediction if it's not terminal.
        <InitialOutput
          hideVersionMismatchWarning={hideVersionMismatchWarning}
          outputVersion={initialPredictionVersionWithFallback}
          prediction={initialPrediction?.prediction ?? null}
          versionMismatch={
            initialPredictionVersionWithFallback.id !== version.id
          }
        />
      )}
    </div>
  );
}

function PostSubmitOutput({
  prediction,
  updateStartedAt,
  updateState,
}: {
  prediction: Prediction | undefined;
  updateStartedAt: Date;
  updateState: PlaygroundFormState;
}) {
  return (
    <>
      {updateState.status === "pending" && (
        <PredictionLoadingState>Starting...</PredictionLoadingState>
      )}
      {updateState.status === "error" && (
        <PredictionErrorState
          error={updateState.error}
          fallback="Failed to fetch prediction."
        />
      )}
      {updateState.status === "success" && prediction && (
        <PredictionDetailOutput
          isNewPrediction
          prediction={prediction}
          queryStartedAt={updateStartedAt}
        />
      )}
    </>
  );
}
