import * as Sentry from "@sentry/react";
import { snakeCase } from "lodash-es";
import * as rudderanalytics from "rudder-sdk-js";
import { getMetaTagContent } from "./util";

// https://blog.testdouble.com/posts/2021-12-08-how-to-remove-utm-query-parameters-from-your-urls/
function cleanUrl() {
  if (window.URLSearchParams && window.history) {
    const params = new URLSearchParams(window.location.search);
    let urlShouldBeCleaned = false;
    for (const key of Array.from(params.keys())) {
      if (key.indexOf("utm_") === 0) {
        urlShouldBeCleaned = true;
        params.delete(key);
      }
    }
    if (urlShouldBeCleaned) {
      const query = params.toString() ? `?${params.toString()}` : "";
      const cleanUrl = window.location.pathname + query + window.location.hash;
      window.history.replaceState(null, "", cleanUrl);
    }
  }
}

/**
 * Looks for any HTML elements with the `data-analytics-event` attribute and
 * attaches a click event listener to them, which will send an analytics event
 * via trackEvent when clicked.
 */
function initializeAnalyticsTracking() {
  const eligibleElements = document.querySelectorAll("[data-analytics-event]");
  for (const element of eligibleElements) {
    const event = element.getAttribute("data-analytics-event");
    if (!event) return;

    element.addEventListener("click", () => {
      trackEvent(event);
    });
  }
}

export function initAnalytics() {
  cleanUrl();

  const userId = getMetaTagContent("user-id");
  const anonymousId = getMetaTagContent("anonymous-id");

  const rudderstackKey = getMetaTagContent("rudderstack-write-key");
  const rudderstackDataPlaneUrl = getMetaTagContent(
    "rudderstack-data-plane-url"
  );

  const analyticsEnabled = getMetaTagContent("rudderstack-analytics-enabled");
  if (analyticsEnabled !== "True") {
    return;
  }

  if (!rudderstackKey || !rudderstackDataPlaneUrl) {
    Sentry.captureEvent({
      message: "Missing Rudderstack write key or data plane URL.",
    });
    return;
  }

  rudderanalytics.load(rudderstackKey, rudderstackDataPlaneUrl, {
    onLoaded: () => {
      if (anonymousId) {
        rudderanalytics.setAnonymousId(anonymousId);
      }

      if (userId) {
        rudderanalytics.identify(userId);
      }

      rudderanalytics.page();
      initializeAnalyticsTracking();
    },
  });
}

export function trackEvent(
  event: string,
  properties: rudderanalytics.apiObject = {}
): void {
  const snakeCaseProperties = Object.fromEntries(
    Object.entries(properties).map(([key, value]) => [snakeCase(key), value])
  );

  rudderanalytics.track(event, {
    event_id: snakeCase(event),
    ...snakeCaseProperties,
  });
}
